<template>
  <div class="resContainer">
    <div class="imgBox">
      <img class="touchTip"
           src="../../assets/touckTip.png"
           alt=""
           srcset="">
      <img class="resImg"
           v-if="showImg === 1"
           src="../../assets/1.jpg"
           alt=""
           srcset="">
      <img class="resImg"
           v-if="showImg === 2"
           src="../../assets/2.jpg"
           alt=""
           srcset="">
      <img class="resImg"
           v-if="showImg === 3"
           src="../../assets/3.jpg"
           alt=""
           srcset="">
      <img class="resImg"
           v-if="showImg === 4"
           src="../../assets/4.jpg"
           alt=""
           srcset="">
      <img class="resImg"
           v-if="showImg === 5"
           src="../../assets/5.jpg"
           alt=""
           srcset="">
    </div>
  </div>
</template>

<script>
export default {
  props: ['currentPage'],
  data() {
    return {
      showImg: 1,
    };
  },
  watch: {
    currentPage(index) {
      if (index === 3) {
        // 发送结果页面埋点
        console.log('结果页面——————————');
        aplus_queue.push({
          action: 'aplus.sendPV',
          arguments: [{
            is_auto: false,
          }, {
            res: this.showImg,
            page_name: '结果页面',
          }],
        });
      }
    },
  },
  mounted() {
    this.showImg = this.random(1, 6);
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['pageName', 'CLK', {
        title: '结果',
        showImg: this.showImg,
      }],
    });
  },
  methods: {
    random(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
  },
};
</script>

<style lang="less" scoped>
.resContainer {
  // min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  .imgBox {
    position: relative;
    .touchTip {
      position: absolute;
      z-index: 10;
      width: 173px;
      height: 75px;
      bottom: 94px;
      left: 142px;
      pointer-events: none;
    }
    .resImg {
      width: 750px;
      height: auto;
      display: block;
    }
  }
}
</style>
