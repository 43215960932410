<template>
  <div class="que-container">
    <img class="preButton"
    @click="$emit('preQue')"
    src="../../assets/preButton.png" alt="" srcset="">
    <img src="../../assets/questions/L-R.png"
         class="L-R"
         alt=""
         srcset="">
    <div class="baImage question">
      <img class="question_tip"
           src="../../assets/questions/question-tip.png"
           alt=""
           srcset="">
      <img class="question_water animate__animated animate__heartBeat"
           src="../../assets/questions/water.png"
           alt=""
           srcset="">
      <div class="question_text animate__animated animate__zoomIn">
        {{textObj.que}}
      </div>
      <div class="pageNum">{{textObj.id}}/5</div>
    </div>
    <div class="answers">
      <div class="baImage answer1 animate__animated animate__fadeInLeft"
           @click="handClickAns(1)">
        <div class="text"
             :style="ansStrly">
          {{textObj.ans1}}
        </div>
      </div>
      <div class="baImage answer2 animate__animated animate__fadeInRight"
           @click="handClickAns(2)">
        <div class="text"
             :style="ansStrly">
          {{textObj.ans2}}
        </div>
      </div>
      <div class="baImage answer3 animate__animated animate__fadeInLeft"
           @click="handClickAns(3)">
        <div class="text"
             :style="ansStrly">
          {{textObj.ans3}}
        </div>
      </div>
    </div>
    <div class="logo animate__animated animate__fadeInUp">
      <img src="../../assets/questions/logo.png"
           alt=""
           srcset="">
    </div>

  </div>
</template>

<script>
export default {
  props: {
    textObj: {
      typeof: Object,
      require: false,
      default: () => ({}),
    },
    ansFontSize: {
      typeof: String,
      require: false,
      default: '3.733',
    },
  },
  data() {
    return {

    };
  },
  mounted() {
    console.log('答题页面');
    aplus_queue.push({
      action: 'aplus.sendPV',
      arguments: [{
        is_auto: false,
      }, {
        question: this.textObj.que,
        page_name: '答题页面',
      }],
    });

    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['pageName', 'CLK', {
        title: '答题页面',
        question: this.textObj.que,
      }],
    });
  },
  computed: {
    ansStrly() {
      return {
        fontSize: `${this.ansFontSize}px`,
        // marginTop: `-${this.ansFontSize / 2}vw`,
      };
    },
  },
  methods: {
    handClickAns(index) {
      this.$emit('clickAns', index);
    },
  },
};
</script>

<style lang="less" scoped>
.que-container {
  // min-height: 100
  height: 100%;
  background: linear-gradient(0deg, #ffffff 15%, #00ceff 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.L-R {
  position: absolute;
  width: 67px;
  right: 0;
  top: 569px;
}
.baImage {
  background-repeat: no-repeat;
  background-size: contain;
}
.question {
  margin-top: 135px;
  background-image: url("../../assets/questions/question.png");
  height: 547px;
  width: 570px;
  position: relative;
  &_tip {
    width: 74px;
    position: absolute;
    left: -38px;
    top: 17px;
  }
  &_water {
    width: 95px;
    position: absolute;
    bottom: 78px;
    right: 68px;
  }
  &_text {
    width: 427px;
    white-space: pre-line;
    margin-top: 150px;
    margin-left: 25px;
    font-size: 32px;
    font-weight: 400;
    line-height: 83px;
    font-family: SourceHanSansSC Regular;
  }
}
.answers {
  font-family: SourceHanSansSC;

  .answer1 {
    width: 559px;
    height: 115px;
    background-image: url("../../assets/questions/R1-1.png");
    margin-bottom: 14px;
    overflow: hidden;
    .text{
      margin-top: 18px;
    }
  }
  .answer2 {
    width: 546px;
    height: 120px;
    margin-bottom: 22px;
    background-image: url("../../assets/questions/R1-2.png");
    overflow: hidden;
    .text{
      margin-top: 21px;
    }
  }
  .answer3 {
    width: 565px;
    height: 124px;
    background-image: url("../../assets/questions/R1-3.png");
    margin-left: -18px;
    overflow: hidden;
    .text {
      margin-top: 15px;
      margin-left: 53px;
    }
  }
  .text {
    height: 88px;
    font-size: 30px;
    display: flex;
    align-items: center;
    margin-left: 29px;
    overflow: hidden;
  }
}

.logo {
  // margin-top: 111px;
  position: absolute;
  bottom: 68px;
  // background: #ffffff;
  padding: 15px;
  img {
    width: 150px;
  }
}
.preButton{
  width: 117px;
  height: auto;
  position: absolute;
  top: 45px;
  right: 45px;
}

.pageNum{
  position: absolute;
  top: 75px;
  right: 60px;
  font-size:28px;
  color: #231815;
}
</style>
