<template>
  <div class="container" >
    <!-- <div class="audio" v-if="currentPage==1" @click="handleAudio">
      <img v-if="!audioIsPlay" src="../assets/close.png" alt="" srcset="">
      <img v-else src="../assets/open.png" alt="" srcset="">
    </div> -->
    <!-- <audio ref="audio" src="../assets/bgm.mp3" preload="auto"></audio> -->

    <!-- <transition name="animate__fadeIn"> -->
    <Home v-if="currentPage === 1"
          class="animate__animated  animate__fadeIn"
          @nextPage="handNextPage"></Home>
    <!-- </transition> -->
    <!-- 页面控制 -->
    <div v-if="currentPage === 2" class="questionsPage">
      <template v-for="(item,index) in questions">
        <Questions class="animate__animated  animate__fadeIn"
                   @clickAns="(ansIndex)=>handleAns(ansIndex,index)"
                   @preQue="handlePreQue(index)"
                   v-if="currentQuestion === index"
                   :key="index"
                   :ansFontSize="item.ansFontSize"
                   :textObj="item"></Questions>
      </template>
    </div>

    <div v-show="currentPage === 3">
      <Result :currentPage="currentPage" class="animate__animated  animate__fadeIn"></Result>
    </div>

  </div>
</template>

<script>
import Questions from './components/questions.vue';
import Result from './components/result.vue';
import Home from './Home.vue';
import testimg from '../assets/1.jpg';

export default {
  components: {
    Questions,
    Result,
    Home,
  },
  props: {
    status: {
      type: Number,
      require: false,
      default: 1,
    },
  },
  data() {
    return {
      source: '',
      audioIsPlay: false,
      currentPage: 1,
      currentQuestion: 0,
      isFirtAudioPlay: false,
      questions: [
        {
          que: `TA跟你每次都超有话聊,
你的直觉是?`,
          ans1: 'TA在养鱼',
          ans2: '我们就是世间绝配',
          ans3: '先喝口宝矿力水特冷静下',
          ansFontSize: '27px',
          id: 1,
        },
        {
          que: '如何确定TA也对你心动?',
          ans1: '直接告白,声量大了才听得到',
          ans2: '送瓶宝矿力水特试探一下',
          ans3: '发一条pyq仅TA可见,看TA反应',
          ansFontSize: '27px',
          id: 2,
        },
        {
          que: `以下哪个时刻,
让你最“燥”动?`,
          ans1: '假装淡定,接过TA喝过的宝矿力水特',
          ans2: '站在TA面前,马上要说出那句话',
          ans3: '聊天界面显示“对方正在输入中..."',
          ansFontSize: '27px',
          id: 3,
        },
        {
          que: `看到TA在运动场上大汗淋漓,
你会?`,
          ans1: '拍下TA的好身材做屏保',
          ans2: '先看比分猜输嬴',
          ans3: '提前给TA备好宝矿力水特',
          ansFontSize: '27px',
          id: 4,
        },
        {
          que: `干燥的冬天,
你最喜欢的事情是?`,
          ans1: '和TA一起躺在暖气房,听雪落下的声音',
          ans2: '带上宝矿力水特，跟TA打卡冬日美景',
          ans3: '拉上TA一边泡脚一边看剧',
          ansFontSize: '27px',
          id: 5,
        },
      ],
    };
  },
  mounted() {
    // let {} = this.$router
    console.log('status', this.status);
    const { source } = this.$route.query;
    console.log('🚀 ~  mounted ~ source', source);
    if (source && source === 'bilibili') {
      this.source = source;
      this.$store.commit('setSource', source);
    }
    if (this.status === 2) {
      this.currentPage = 2;
    }
  },
  methods: {
    handletest() {
      // const msg = { position: 'game_load', data: 'success' };
      // window.parent.postMessage(msg, ' http://togame.iqiyi.com ');
      // console.log(window);
      // console.log(window.wx.closeWindow());
      // eslint-disable-next-line
      // console.log(_$$$_qy_native_bridge_);
      // const a = document.createElement('a');
      // const event = new MouseEvent('click');

      // a.download = '下载图片名称';
      // a.href = testimg;

      // a.dispatchEvent(event);
      // this.savePicture(testimg);
      console.log('🚀 ~ file: control.vue ~ line 136 ~ handletest ~ testimg', testimg);
    },
    savePicture(Url) {
      const blob = new Blob([''], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = Url;
      // eslint-disable-next-line
      a.download = Url.replace(/(.*\/)*([^.]+.*)/ig, '$2').split('?')[0];
      const e = document.createEvent('MouseEvents');
      e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
      URL.revokeObjectURL(url);
    },
    // handlePlayAu() {
    //   console.log('asd');
    //   if (!this.isFirtAudioPlay) {
    //     this.$refs.audio.play();
    //   }
    // },
    getText() {
      const textAll = this.questions.reduce((acc, item) => `${acc}${item.que}${item.ans1}${item.ans2}${item.ans3}`, '');
      console.log('🚀 ~ file: control.vue ~ line 62 ~ textAll ~ textAll', textAll);
    },
    /**
     * ansIndex 选择的答案
     * quesIndex 回答的题目
     */
    handleAns(ansIndex, quesIndex) {
      console.log('🚀 ~ file: control.vue ~ line 74 ~ handleAns ~ quesIndex', quesIndex);
      console.log('🚀 ~ file: control.vue ~ line 74 ~ handleAns ~ ansIndex', ansIndex);
      console.log('asd');
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['ans', 'CLK', {
          quesIndex,
          ansIndex,
        }],
      });
      // 给当前的答题选择一个结果
      this.questions[quesIndex].ans = ansIndex;
      if (quesIndex === 4) {
        this.handNextPage(3);
        return;
      }
      this.currentQuestion = quesIndex + 1;
    },
    handNextPage(index) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['pageName', 'CLK', {
          title: index,
        }],
      });

      if (index === 2) {
        aplus_queue.push({
          action: 'aplus.record',
          arguments: ['start', 'CLK'],
        });
        if (this.source === 'bilibili') {
          this.$router.push('/questions?source=bilibili');
          // window.
          return;
        }
      }
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['nextPage', 'CLK', {
          pageIndex: index,
        }],
      });

      this.currentPage = index;
    },

    handlePreQue(index) {
      if (index === 0) {
        this.currentPage = 1;
        if (this.$store.state.source === 'bilibili') {
          this.$router.push('/?source=bilibili');
        }
        return;
      }
      this.currentQuestion = index - 1;
    },
    // handleAudio() {
    //   const { audio } = this.$refs;
    //   console.log('🚀 ~ file: control.vue ~ line 140 ~ handleAudio ~ audio', audio);
    //   if (!this.audioIsPlay) {
    //     audio.play();
    //   } else {
    //     audio.pause();
    //   }
    // },
    // handleVideoPlaying() {
    //   // 判断视频暂停之后是否要继续播放音乐
    //   if (this.audioIsPlay) {
    //     this.beforeAudioPlay = true;
    //   } else {
    //     this.beforeAudioPlay = false;
    //   }
    // },
    // handleVideoPause() {
    //   if (this.beforeAudioPlay) {
    //     this.$refs.audio.play();
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.animate__animated.animate__fadeIn {
  --animate-duration: 1s;
}

.questionsPage{
  height: 100%;
      background: linear-gradient(
0deg, #ffffff 10%, #00ceff 100%);
}

.container{
  position: relative;
  height: 100%;
  .audio{
    position: absolute;
    width: 3vw;
    height: 3vw;
    z-index: 99;
    right: 20px;
    top: 500px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
</style>
