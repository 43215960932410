import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    source: undefined,
  },
  mutations: {
    setSource(state, value) {
      this.state.source = value;
    },
  },
  actions: {
  },
  modules: {
  },
});
