<template>
  <div class="home">
    <div class="top" v-if="source !== 'bilibili'">
      <div class="videoP" @click="handlePlay" v-if="!isFistPlay"></div>
      <div class="top_move" @click.stop="handlePlay">
        <img class="play" src="../assets/p1/play.png" v-if="!isPlaying" alt="" srcset="">
        <Loading type="spinner" v-if="videoLoading" class="play"></Loading>
        <video ref="video"
        v-if="source !== 'bilibili'"
        webkit-playsinline="true"
        playsinline="true"
        loop="loop"
        @canplaythrough="videoLoaded"
        autoplay
        x5-video-player-type=”h5”
        src="../assets/move.mp4"
        preload poster="../assets/movebg.jpeg" >
        您的浏览器不支持 video 标签。
        </video>
      </div>
      <!-- <img class="top_l"
           src="../assets/p1/l-白.png"
           alt=""
           srcset=""> -->
    </div>
    <div class="container">
      <img class="title animate__animated animate__bounceIn"
           src="../assets/p1/title.png"
           alt=""
           srcset="">
      <img class="L"
           src="../assets/p1/L.png"
           alt=""
           srcset="">
      <div class="bgBox">
        <img class="dec l-left"
             src="../assets/p1/l-L.png"
             alt=""
             srcset="">
        <img class="dec l-right"
             src="../assets/p1/l- R.png"
             alt=""
             srcset="">
        <img class="dec l-left2"
             src="../assets/p1/l-红白.png"
             alt=""
             srcset="">
        <img class="dec l-right2"
             src="../assets/p1/l-空白.png"
             alt=""
             srcset="">
        <img class="animate__animated animate__flipInX animate__delay-2s text text-1 "
             src="../assets/p1/text-1.png"
             alt=""
             srcset="">

        <div class="text-2-box animate__animated animate__flipInX">
          <img class="text text-2"
               src="../assets/p1/text-2.png"
               alt=""
               srcset="">
        </div>

        <img class="text text-3 animate__animated animate__flipInX"
             src="../assets/p1/text-3.png"
             alt=""
             srcset="">
        <img class="botton animate__animated animate__flipInX"
             @click="$emit('nextPage',2)"
             src="../assets/p1/botton.png"
             alt=""
             srcset="">
        <img class="botton-tip  animate__animated animate__bounceIn"
             src="../assets/p1/button-tip.png"
             alt=""
             srcset="">
      </div>
    </div>
  </div>
</template>

<script>
import { Loading } from 'vant';
import { mapState } from 'vuex';

export default {
  components: {
    Loading,
  },
  data() {
    return {
      videoLoading: false,
      isPlaying: false,
      isFistPlay: false,
    };
  },
  computed: {
    ...mapState(['source']),
  },
  mounted() {
    aplus_queue.push({
      action: 'aplus.sendPV',
      arguments: [{
        is_auto: false,
      }, {
        page_name: '首页',
      }],
    });

    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['pageName', 'CLK', {
        title: '首页',
      }],
    });

    // Toast.loading({
    //   message: '加载中...',
    //   forbidClick: true,
    //   loadingType: 'spinner',
    // });

    const ua = navigator.userAgent;
    console.log('🚀 ~ file: Home.vue ~ line 89 ~ mounted ~ ua_________________', ua);
    console.log(ua.indexOf('Android') > 0);
    const { source } = this.$route.query;
    if (source === 'bilibili') return;
    console.log('this.source', this.source);
    const { video } = this.$refs;
    if (!video) return;
    this.videoLis();
    // 监听微信
    document.addEventListener(
      'WeixinJSBridgeReady',
      () => {
        console.log('WeixinJSBridgeReady');
        // video.play();
        if (typeof WeixinJSBridge !== 'undefined') {
          // eslint-disable-next-line
          WeixinJSBridge.invoke('getNetworkType', {}, (e) => {
            console.log('getNetworkType');
            // 利用该方法进行自动播放
            video.play();
            this.videoLoading = true;
          });
        }
      },
      false,
    );

    // 监听dom原属加载完成
    document.addEventListener('DOMContentLoaded', () => {
      video.play();
    });

    // 监听点击播放
    document.addEventListener('touchstart', () => {
      // 判断如果没有播放过的话则自动播放
      if (!this.isFistPlay) {
        video.play();
      }
    }, false);
  },
  methods: {
    handlePlay() {
      if (this.source === 'bilibili') return;
      console.log(' ~ line 155 ~ handlePlay ~ this.source ', this.source);
      const { video } = this.$refs;
      if (!video) return;
      if (this.isPlaying) {
        video.pause();
      } else {
        video.play();
      }
    },
    videoLis() {
      const { video } = this.$refs;
      video.addEventListener('play', () => {
        this.isPlaying = true;
        this.videoLoading = true;
      });

      video.addEventListener('playing', () => {
        this.isPlaying = true;
        this.isFistPlay = true;
        this.videoLoading = false;
        this.$emit('playing');
      });
      video.addEventListener('pause', () => {
        this.isPlaying = false;
        this.$emit('pause');
      });
    },
    videoLoaded() {
      console.log('准备自动播放视频');
      if (!this.$refs.video) return;
      this.$refs.video.play();
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  // height: 488px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &_move {
    // width: 556px;
    // height: 313px;
    // padding: 30px;
    // background: rgb(249, 168, 168);
    background: url('../assets/movebg.jpeg');
    background-repeat: no-repeat;
    // background-size: cover;
    background-size: 100% 100%;
    overflow: hidden;
    position: relative;
    height: 414px;
    width: 750px;
    .play{
      position: absolute;
      top: 50%;
      left: 50%;
      transform:translate(-50%,-50%);
      width: 56px;
      height: 92px;
    }
    video{
      width: 100%;
      height: 100%;
      display: block;
      object-fit:cover;
    }
  }

  &_l {
    position: absolute;
    top: 50px;
    left: 62px;
    z-index: -1;
    display: block;
  }
}
.container {
  background: #0075b8;
  overflow: hidden;
  flex: 1;
  // min-height: calc(100vh - 488px);
  //  min-height: calc(100vh - 410px);
  // overflow: hidden;
  position: relative;
  padding: 0vw;
  .L {
    width: 16px;
    height: auto;
    position: absolute;
    bottom: 218px;
    left: 0vw;
  }
  .title {
    // width: 603px;
    // height: 304px;
    width: 603px;
    height: 275px;
    // margin-top: -12vw;
    position: absolute;
    top: 15px;
    left: 75px;
    z-index: 10;
    // transform: translateX(-50%);
  }
  .bgBox {
    border: 0vw solid #003351;
    border-bottom: none;
    margin: 0 auto;
    margin-top: 75px;
    width: 705px;
    height: 100%;
    // min-height: calc(100vh - 221px);
    //  height: calc(100vh - 497px);
    // height: calc(100vh - 105px);
    background: linear-gradient(141deg, #d1f4ff 60%, #72e3ff 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .text {
      margin-top: 47px;
      height: 80px;
      width: auto;
    }
    .text-1 {
      margin-top: 240px;
      animation-delay:0.2s;
    }
    .text-2-box {
      animation-delay:0.4s;
      position: relative;
      background-image: url("../assets/p1/text-2-bg.png");
      background-repeat: no-repeat;
      background-size: 396px;
      background-position-x: center;
      background-position-y: 68px;
      .text-2-bg {
        width: 396px;
        height: 15px;
        position: absolute;
        top: 25px;
        margin: 0 auto;
        z-index: 0;
      }
    }
    .text-3{
      animation-delay:0.6s;
    }
    .botton {
      animation-delay:0.8s;
      margin-top: 48px;
      height: 120px;
      width: auto;
    }
    .botton-tip {
      animation-delay:1.2s;
      margin-top: 11px;
      height: 24px;
    }
    .dec {
      position: absolute;
      display: block;
    }
    .l-left {
      right: 0;
      height: 193px;
      bottom: 254px;
    }
    .l-left2 {
      top: 180px;
      width: 41px;
      right: -15px;
    }
    .l-right {
      height: 67px;
      top: 245px;
      left: 0;
    }
    .l-right2 {
      height: 67px;
      bottom: 45px;
      left: -20px;
    }
  }
}

.videoP{
  width: 750px;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 999999;
  top: 0;
  left: 0;
}

.home{
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
