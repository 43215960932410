<template>
    <div style="height:100%">
       <control :status="2"></control>
    </div>
</template>

<script>
import Control from './Control.vue';

export default {
  components: {
    Control,
  },
};
</script>
