<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import axios from 'axios';
// import VConsole from 'vconsole';

export default {
  created() {
    axios.get('v.json', {
      params: {
        time: Date.parse(new Date()),
      },
    }).then((res) => {
      const { data, status } = res;
      console.log('🚀 ~ file: App.vue ~ line 15 ~ axios.get ~ data', data);
      if (status === 200) {
        const { v, source } = this.$route.query;
        console.log('🚀 ~ file: App.vue ~ line 18 ~ axios.get ~ v', v);
        if (v !== data.v) {
          // this.$router.push({
          //   path: '/',
          //   query: Object.assign(this.$route.query, { v: 1.2 }),
          // });
          window.location = `${window.location.origin}/?v=${data.v}&source=${source}`;
        }
      }
    });
    this.init();
    // const vConsole = new VConsole();
    // console.log('🚀 ~ file: App.vue ~ line 15 ~ created ~ vConsole', vConsole);
    aplus_queue.push({
      action: 'aplus.sendPV',
      arguments: [{
        is_auto: false,
      }, {
        page_name: 'app',
        ua: window.navigator.userAgent,
      }],
    });
    window.addEventListener('resize', this.setRoomSize);
    window.addEventListener('pageshow', (e) => {
      if (e.persisted) {
        this.setRoomSize();
      }
    });
    // window.addEventListener('load', this.setRoomSize());
  },
  mounted() {
    this.setRoomSize();
  },
  methods: {
    setRoomSize() {
      // eslint-disable-next-line
      const Width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      console.log('🚀 ~ file: App.vue ~ line 27 ~ created ~ Width', Width);
      if (Width > 600) {
        console.log('asdasd_______+++++++');
        document.documentElement.style.fontSize = '37.5px';
        document.getElementById('app').className = 'pcApp';
      } else {
        document.documentElement.style.fontSize = '10vw';
      }
    },
    init() {
      const url = 'https://pocari-sweat-api.20kb.art/api/get-wxconfig';
      axios.get(url, {
        params: {
          url: window.location.href,
        },
      }).then((res) => {
        const { status, data } = res;
        if (status === 200) {
          this.initWx(data);
        }
        console.log('🚀 ~ file: App.vue ~ line 20 ~ init ~ res', res);
      });
    },
    initWx(confData) {
      const { nonceStr, sign: signature, timestamp } = confData;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: 'wx40d2b49780efa2bc', // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature, // 必填，签名
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ', 'onMenuShareWeibo', 'onMenuShareQZone'], // 必填，需要使用的JS接口列表
      });

      wx.ready(() => {
        // config信息验证后会执行ready方法，
        // 所有接口调用都必须在config接口获得结果之后，
        // config是一个客户端的异步操作，
        // 所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。
        // 对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        console.log('微信验证成功');
        this.initShare({
          title: '测一测你和谁更来电?', desc: '心动信号!遇见来电的TA', link: window.location.href, imgUrl: `${window.location.origin}/shareImg.jpg`,
        });
      });
    },
    initShare(shareData) {
      const {
        title, desc, link, imgUrl,
      } = shareData;

      // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
      wx.updateAppMessageShareData({
        title, // 分享标题
        desc, // 分享描述
        link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl, // 分享图标
        success() {
          // 设置成功
          console.log('“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）');
        },
      });

      // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
      wx.updateTimelineShareData({
        title, // 分享标题
        link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl, // 分享图标
        success() {
          // 设置成功
          console.log('分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）');
        },
      });

      // 获取“分享到朋友圈”按钮点击状态及自定义分享内容接口（即将废弃）
      wx.onMenuShareTimeline({
        title, // 分享标题
        link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl, // 分享图标
        success() {
          // 用户点击了分享后执行的回调函数
          console.log('获取“分享到朋友圈”按钮点击状态及自定义分享内容接口');
        },
      });

      // 获取“分享给朋友”按钮点击状态及自定义分享内容接口（即将废弃）
      wx.onMenuShareAppMessage({
        title, // 分享标题
        desc, // 分享描述
        link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl, // 分享图标
        type: 'link', // 分享类型,music、video或link，不填默认为link
        dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
        success() {
          // 用户点击了分享后执行的回调函数
          console.log('获取“分享给朋友”按钮点击状态及自定义分享内容接口');
        },
      });

      // 获取“分享到QQ”按钮点击状态及自定义分享内容接口（即将废弃）
      wx.onMenuShareQQ({
        title, // 分享标题
        desc, // 分享描述
        link, // 分享链接
        imgUrl, // 分享图标
        success() {
          // 用户确认分享后执行的回调函数
          console.log('获取“分享到QQ”按钮点击状态及自定义分享内容接口');
        },
        cancel() {
          // 用户取消分享后执行的回调函数
          console.log('取消 - “分享到QQ”按钮点击状态及自定义分享内容接口');
        },
      });

      // 获取“分享到腾讯微博”按钮点击状态及自定义分享内容接口
      wx.onMenuShareWeibo({
        title, // 分享标题
        desc, // 分享描述
        link, // 分享链接
        imgUrl, // 分享图标
        success() {
          // 用户确认分享后执行的回调函数
          console.log('获取“分享到腾讯微博”按钮点击状态及自定义分享内容接口');
        },
        cancel() {
          // 用户取消分享后执行的回调函数
          console.log('取消-“分享到腾讯微博”按钮点击状态及自定义分享内容接口');
        },
      });

      // 获取“分享到QQ空间”按钮点击状态及自定义分享内容接口（即将废弃）
      wx.onMenuShareQZone({
        title, // 分享标题
        desc, // 分享描述
        link, // 分享链接
        imgUrl, // 分享图标
        success() {
          // 用户确认分享后执行的回调函数
          console.log('获取“分享到QQ空间”按钮点击状态及自定义分享内容接口（即将废弃）');
        },
        cancel() {
          // 用户取消分享后执行的回调函数
          console.log('取消 - “分享到QQ空间”按钮点击状态及自定义分享内容接口（即将废弃）');
        },
      });
    },
  },
};
</script>

<style lang="less">
*{
  padding: 0;
  margin: 0;
}

html{
  font-size: 13.3333vw;
}

body{
  max-width: 750px;
  min-height:700px;
  overflow: hidden;
  margin: 0 auto !important;
  position: relative;
}

#app{
  height: 100vh;
}

.pcApp{
  height: 700px !important;
}

@font-face {
    font-family: 'SourceHanSansSC';
    font-display: swap;
    src:url('./assets/SourceHanSansSC.ttf') format('truetype');
}

@font-face {
    font-family: 'SourceHanSansSC Regular';
    font-display: swap;
    src:url('./assets/SourceHanSansSC Regular.ttf') format('truetype')
}
</style>
